<template>
  <div class="big">
    <div class="capacity">
      <el-row>
        <el-col :span="6">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }"
              >分贝首页</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/consulthome' }"
              >资讯</el-breadcrumb-item
            >
            <el-breadcrumb-item>资讯详情</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
      <div class="title-name" v-if="[0,2].includes(data.detailType)">{{ data.title }}</div>
      <div class="title-time" v-if="[0,2].includes(data.detailType)">
        <span>{{ data.author }}</span
        ><span>{{ settime(data.releaseTime) }}</span>
      </div>

      <!-- <div class="btn">
            <button @click="$router.push('/consulthome')">返回资讯首页</button>
          </div> -->
      <div class="describe">
        <ul class="left">
          <div
            v-if="data.detailType == 0"
            class="content"
            v-html="data.content"
          ></div>
          <video
              v-if="data.detailType == 2"
              style="width: 100%;height: 500px;background: black;"
              controls
              :src="data.url"
          ></video>
          <div class="btn">
            <button @click="$router.push('/consulthome')">返回资讯首页</button>
          </div>
        </ul>
        <div class="right">
          <div class="head">
            <img src="../assets/group.png" alt="" /><span>最新动态</span>
          </div>
          <ul class="timeline">
            <li
              class="time-item"
              v-show="$route.query.id != item.id"
              v-for="item in arr"
              :key="item.id"
            >
              <div class="round"></div>
              <div class="time">{{ settime1(item.releaseTime) }}</div>
              <div class="title" @click="details(item.id)">
                {{item.title}}
                <!-- 方式十分士大夫胜多负少发射点发射点反对士大夫十分十分大师傅士大夫大师傅似的 -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { newsdetail, newsList } from '../api/index'
import moment from 'moment';
export default {
  created () {
    this.detaillist()
    newsList({ type: this.$route.query.type, pageNum: 1, pageSize: 10 }).then((res) => {
      this.arr = res.data.list;
    })
  },
  data () {
    return {
      data: {

      },
      calHeight: '',
      arr: []
    }
  },
  updated () {
  },
  methods: {
    detaillist () {
      newsdetail({ id: this.$route.query.id }).then(res => {
        this.data = res.data
        if (res.data.detailType == 1) {
          window.open(res.data.url);
          this.$router.back()
        }
      })
    },
    settime (val) {
      return moment(val).format('YYYY.MM.DD h:mm:ss');
    },
    settime1 (val) {
      return moment(val).format('MM.DD');
    },
    details(val){
        this.$route.query.id=val
          this.detaillist()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/initialization.scss";

.big {
  background-image: url("../assets/detailsbg.png");
  background-repeat: repeat-x;
  width: 100%;
  overflow: hidden;
  .capacity {
       ::v-deep .el-breadcrumb__inner{
      color: rgba(153,153,153,1);
  }
  ::v-deep .is-link{
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color:rgba(51,51,51,1);
    letter-spacing: 0px;
  }
    width: 1200px;
    box-sizing: border-box;
    padding-left: 60px;
    padding-right: 40px;
    margin: 0px auto;
    margin-top: 30px;
    background-color: #ffffff;
    overflow: hidden;
    .el-row {
      margin-top: 24px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      letter-spacing: 0px;
      margin-bottom: 30px;
    }
    .title-name {
      font-size: 30px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: LEFT;
      color: #333333;
    }
    .title-time {
      margin-top: 8px;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #999999;
      margin-bottom: 24px;
      span {
        margin: 0px 6px;
      }
    }
    .content {
      min-height: 50vh;
    }
    .content >>> video {
      width: 100%;
    }
    .content >>> img {
      width: 100%;
    }
    .btn {
      width: 100%;
      margin-top: 60px;
      margin-bottom: 47px;
      text-align: center;
      button {
        width: 202px;
        height: 50px;
        opacity: 1;
        background: #ff004d;
        border: none;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 14px;
      }
    }
    .describe {
      display: flex;
      padding-bottom: 60px;
      .left {
        width: 720px;
        list-style: none;
        padding-left: 0px;
        margin: 0px;
        li {
          text-indent: 25px;
          font-size: 15px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #222222;
          line-height: 24px;
          display: inline-block;
          text-align: justify;
        }
        .image {
          //   border: 1px solid red;
          box-sizing: border-box;
          width: 100%;
          text-align: center;
          img {
            width: 680px;
            height: 300px;
          }
        }
        div:nth-of-type(1) {
        //   margin-top: 30px;
          margin-bottom: 12px;
        }
        div:nth-of-type(2) {
          margin-top: 48px;
          margin-bottom: 32px;
        }
        .btn {
          width: 100%;
          margin-top: 60px;
          margin-bottom: 47px;
          text-align: center;
          button {
            width: 202px;
            height: 50px;
            opacity: 1;
            background: #ff004d;
            border: none;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            line-height: 14px;
          }
        }
      }
      .right {
        margin-left: 57px;
        width: 272px;
        // border: 1px solid;
        margin-top:-13px;
        .head {
          height: 50px;
          border-bottom: 1px solid #cccccc;
          img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
          }
          span {
            font-size: 15px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #333333;
            line-height: 24px;
            vertical-align: middle;
            margin-left: 15px;
          }
        }
        .timeline {
          margin-top: 26px;
          width: 100%;
          border-left: 1px solid #c4c4c4;
          .time-item {
            padding-bottom: 16px;
            position: relative;
            .round {
              left: -5.5px;
              top: 5px;
              position: absolute;
              width: 9px;
              height: 9px;
              border: 1px solid #c4c4c4;
              background: #ffffff;
              border-radius: 50%;
            }
            .time {
              margin-left: 10px;
              width: 52px;
              height: 24px;
              background-image: url("../assets/Union.png");
              background-size: 100% 100%;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: center;
              color: #42245a;
              line-height: 24px;
            }
            .title {
              cursor: pointer;
              margin-top: 12px;
              margin-left: 12px;
              width: 233px;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #333333;
            //   line-height: 20px;
              table-layout: fixed;
              word-wrap: break-all;
              word-break: normal;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
</style>
